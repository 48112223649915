import "./App.css";
import React from "react";
import {Navigate, BrowserRouter, Route, Routes} from "react-router-dom";
import Homepage from "../Homepage/Homepage";
import {ThemeProvider} from "@mui/material";
import { theme } from "./../../theme";
import Footer from "../Footer/Footer";
import Account from "../Account/Account";
import Unsubscribe from "../Unsubscribe/Unsubscribe";
import PhoneVerification from "../PhoneVerification/PhoneVerification";
import PinVerification from "../PinVerification/PinVerification";
import Header from "../Header/Header";
import PickProgram from "../PickProgram/PickProgram";
import CongratsPage from "../CongratsPage/CongratsPage";
import CashCoupon from "../CashCoupon/CashCoupon";
import DeviceLink from "../DeviceLink/DeviceLink";
import AddCoupon from "../AddCoupon/AddCoupon";

import PasswordUpdate from "../PasswordUpdate/PasswordUpdate";
import Questionnaire from "../Account/subPages/Questionnaire/Questionnaire";
import Invoice from "../Invoice/Invoice";
import ProtectedRoute from "./ProtectedRoute";
import {LoadingProvider} from "../../context/LoadingContext";
import {CmsProvider} from "../../context/CmsContext";
import {AuthProvider} from "../../context/AuthContext";
import {AccountProvider} from "../../context/AccountContext";
import usePaymentProvider from "../../services/PaymentProvider.ts";

function App() {
  const paymentProvider = usePaymentProvider();

  return (
    <div className="App">
      <div className="app-wrapper">
        <ThemeProvider theme={theme}>
            <LoadingProvider>
                <CmsProvider>
                    <AuthProvider>
                        <AccountProvider>
                            <BrowserRouter>
                                <Header />
                                <Routes>
                                    <Route exact path="/" element={<Homepage />} />
                                    <Route exact path="/programmata" element={<Homepage goToProducts={true} />}/>
                                    <Route path={"/AddCoupon"} element={<AddCoupon />} />


                                    <Route element={<ProtectedRoute />}>
                                        <Route path={"/Account"} element={<Account />} />
                                        <Route path={"/invoice"} element={<Invoice />} />
                                        <Route path={"/Unsubscribe"} element={<Unsubscribe />} />
                                        <Route path={"/Questionnaire"} element={<Questionnaire />} />
                                        <Route path={"/PickProgram"} element={<PickProgram />} />
                                        <Route path={"/CongratsPage"} element={<CongratsPage />} />
                                        <Route path={paymentProvider.paymentForm} element={paymentProvider.PaymentProviderComponent} />
                                        <Route path={"/PhoneVerification"} element={<PhoneVerification />}/>
                                        <Route path={"/PinVerification"} element={<PinVerification />}/>
                                        <Route path={"/Link"} element={<DeviceLink />} />

                                        <Route path={"/CashCoupon"} element={<CashCoupon />} />
                                        <Route path={"/PasswordUpdate"} element={<PasswordUpdate />}/>
                                    </Route>


                                    <Route path={"*"} element={<Navigate replace to={"/"} />} />
                                </Routes>
                                <Footer />
                            </BrowserRouter>
                        </AccountProvider>
                    </AuthProvider>
                </CmsProvider>
            </LoadingProvider>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default App;