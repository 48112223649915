import React, {useRef, useState, useEffect} from "react";
import "../../GlobalStyles.css";
import Loader from "../../Loader/Loader";
import {useLoading} from "../../../context/LoadingContext";
import AccountsService from "../../../services/AccountsService";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../context/AuthContext";
import {useAccount} from "../../../context/AccountContext";


const EvergentForm = () => {

  const navigate = useNavigate()

  const [errors, setErrors] = useState([]);
  const [iframeUrl, setIframeUrl] = useState(undefined);

  const formRef = useRef(null)

  const account = useAccount();
  const { isLoading, addLoading, removeLoading } = useLoading();
  const {loggedIn} = useAuth()


  useEffect(() => {
    if(!(isLoading("INIT"))){
      addLoading("INIT")
    }
    if(loggedIn){
      if(!account.chargifyAccess){
        navigate(-1);
      }else{
        AccountsService.addPaymentProfile()
            .then(r => {
              setIframeUrl(new URL(r.data.uri));
            })
            .catch(e => {
              setErrors([...errors, e])
            })
        removeLoading("INIT")
      }
    }

  }, [loggedIn]); //eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {

    const handleMessage = (event)=>{
      if (!iframeUrl || event.origin !== iframeUrl.origin) {
        return;
      }
      const data = JSON.parse(event.data);
      if((parseInt(data.status) === 1)){
        console.log("Successful flow")
        navigate("/Account");
      }else {
        console.log("Faillure: ", data)
      }
    }

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [iframeUrl]);  //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formRef.current && iframeUrl) {
      formRef.current.submit()
    }
  }, [iframeUrl]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading() && !iframeUrl ? (
        <Loader />
      ) : (
          <div style={{flex: 1, display: "flex"}}>
            <form hidden={true}
                  target={"ev-iframe"}
                  action={!!iframeUrl ? iframeUrl?.origin + iframeUrl?.pathname : ""}
                  method={"POST"} ref={(reference) => formRef.current = reference}>
              {iframeUrl && iframeUrl.search
                  .replace("?", "")
                  .split("&")
                  .map((token) => {
                    const tokens = token.split("=")
                    return <input key={tokens[0]} name={tokens[0]} defaultValue={tokens[1]}/>
                  })}
            </form>
            {iframeUrl && <iframe name={"ev-iframe"} title={"ev-add-payment-method"} src={iframeUrl}
                                  style={{width: '100%', height: '100vh', border: 'none'}}
            />}
          </div>)}
    </>
  );
};

export default EvergentForm;