import React, {useState, useEffect} from "react";
import "./Account.css";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/UserService";
import AccountsService from "../../services/AccountsService";
import Loader from "../Loader/Loader";
import ProductsService from "../../services/ProductsService";
import GenericError from "../Utilities/GenericError";
import PopUp from "../Utilities/PopUp";
import TextBox from "./components/TextBox/TextBox";
import CheckBox from "./components/CheckBox/CheckBox";
import CouponsService from "../../services/CouponsService";
import CustomDatePicker from "./components/DatePicker/DatePicker";
import CustomSelectBox from "./components/CustomSelectBox/CustomSelectBox";
import { isValidPhoneNumber } from "react-phone-number-input/mobile";
import CardValidationService from "../../services/CardValidationService";
import PinVerification from "../PinVerification/PinVerification";
import {formatDate, gradientGetter, Products} from "./utils";
import {useLoading} from "../../context/LoadingContext";
import {useAuth} from "../../context/AuthContext";
import CardCarousel from "./components/CardCarousel/CardCarousel";
import {useAccount} from "../../context/AccountContext";
import usePaymentProvider from "../../services/PaymentProvider.ts";

function Account() {
    const VILLAGE_ELLIGIBLE_PRODUCTS = Object.freeze([Products.VILLAGE, Products.ULTIMATE])

    const paymentProvider = usePaymentProvider();
    let navigate = useNavigate();
    //context
    let account = useAccount();
    const {isLoading, removeLoading, addLoading} = useLoading();
    const {loggedIn, setCheckLoggedIn} = useAuth();

    const [canUpgrade, setCanUpgrade] = useState(false);
    const [periodEndDate, setPeriodEndDate] = useState("");
    const [hasUnsubscribed, setHasUnsubscribed] = useState(false);
    const [hasUsedCoupon, setHasUsedCoupon] = useState(false);
    const [hasFreeProduct, setHasFreeProduct] = useState(false);

    //Phone number verification
    const [verificationPopup, setVerificationPopup] = useState(false);
    const [verificationErrorPopup, setVerificationErrorPopup] = useState(false);
    const [verificationSuccessPopup, setVerificationSuccessPopup] = useState(false);

    const [paymentProfileList, setPaymentProfileList] = useState([]);


    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        gender: ''
    });
    const [phoneNum, setPhoneNum] = useState('')

    const onUnsubscribeHandle = () => {
        account.clearSessionCoupon();
        navigate("/questionnaire",  {
        state: {
            q: 'unsubscribe',
            productId: account.userData.subscription.product.id,
            pricePoint: account.userData.subscription.product.product_price_point_handle,
            subscriptionState: account.userData.subscription.state,
            next_assessment_at: account.userData?.subscription?.next_assessment_at
        }});
    };

    //Marketing field handling
    const [marketingDisabled, setMarketingDisabled] = useState(false)
    const [acceptMarketingCampaigns, setAcceptMarketingCampaigns] = useState(false);
    const onAcceptMarketingChange = () => {
        setMarketingDisabled(true);
    let value = acceptMarketingCampaigns;
    setAcceptMarketingCampaigns(!value);
    AccountsService.patchUser({ acceptedMarketing: !value })
      .then((response) => {
          setMarketingDisabled(false);
      })
      .catch((error) => {
          setMarketingDisabled(false);
      });
    };



    useEffect(() => {
        if(!(isLoading("INIT"))){
            addLoading("INIT")
        }
        if(loggedIn){
            AccountsService.getProfile()
                .then((response) => {
                    account.setUserData(response.data);
                    setAcceptMarketingCampaigns(response.data.acceptedMarketing);
                    setFormData({
                        firstName: response.data.firstName && response.data.firstName !== "Anonymous" ? response.data.firstName : "",
                        lastName: response.data.lastName && response.data.lastName !== "User" ? response.data.lastName : "",
                        dateOfBirth: response.data.dateOfBirth,
                        gender: response.data.gender
                    })
                    setPhoneNum(response.data.phoneNumber ? response.data.phoneNumber : "")

                    setPeriodEndDate(formatDate(response.data.subscription?.current_period_ends_at));

                    if(response.data.paymentProfileList?.length > 1 && !!response.data?.subscription?.credit_card?.id){
                        const temp = response.data.paymentProfileList?.findIndex(item => item.id === response.data?.subscription?.credit_card?.id)
                        setPaymentProfileList([response.data.paymentProfileList[temp], ...response.data.paymentProfileList?.slice(0, temp), ...response.data.paymentProfileList?.slice(temp + 1)])
                    }else{
                        setPaymentProfileList(response.data.paymentProfileList)
                    }

                    if (response.data.subscription) {
                        setHasUsedCoupon(response.data.subscription.coupon_code ? true : false);
                        ProductsService.getProductReferenceStandalone({
                            productId: response.data.subscription.product.id,
                            pricePoint: response.data.subscription.product.product_price_point_handle,
                        })
                            .then((res) => {
                                // setShowVoucher(res.data.displayName === "Village");
                                setHasFreeProduct(!!res.data.downgrades && res.data.downgrades.length === 0)
                                setCanUpgrade((!!res.data.upgrades && res.data.upgrades.length > 0) || (!!res.data.downgrades && res.data.downgrades.length > 0));
                                const nextProductIdCheck =
                                    response.data.subscription.next_product_id !== undefined
                                        ? response.data.subscription.next_product_id.toString() ===
                                        process.env.REACT_APP_FREE_PRODUCT_ID.toString()
                                            ? true
                                            : false
                                        : false;
                                setHasUnsubscribed(nextProductIdCheck);
                            })
                            .catch((e)=>{

                            })
                    }
                })
                .catch((e)=>{

                })
                .finally(()=>{
                    removeLoading("INIT")
                })
        }
    }, [loggedIn]); //eslint-disable-line react-hooks/exhaustive-deps

  const onChangePaymentInfo = (e) => {
    e.preventDefault();
    account.setChargifyAccess(true);
    account.setChangePayment(true);
    navigate(paymentProvider.paymentForm);
  };

  const onUpgrade = () => {
    setCheckLoggedIn(false);
    account.clearSessionCoupon();
    navigate("/PickProgram");
  };

  const onCashCoupon = () => {
    setCheckLoggedIn(false);
    navigate("/CashCoupon");
  };

  const sendVerificationEmail = () =>{
    addLoading("SENTEMAIlVER")
    AccountsService.actions({redirectUri: process.env.REACT_APP_VERIFY_EMAIL_REDIRECT_URI, action: "VERIFY_EMAIL"})
        .then(()=>{
          setVerificationErrorPopup(false);
          setVerificationSuccessPopup(true);
        })
        .catch((err)=>{
          setVerificationErrorPopup(true);
        })
        .finally(()=>{
          setVerificationPopup(false);
          removeLoading("SENTEMAIlVER")
        })
  }

  const logoutFromAllDevices = (e)=>{
      e.preventDefault();
      addLoading("LOGOUTALLDEV")
      AccountsService.logoutAll()
          .then(()=>{

          })
          .catch((err)=>{
              console.error("Error logout from all devices", err);
          })
          .finally(()=>{
              removeLoading("LOGOUTALLDEV")
          })
  }

    const [isHistoryExpanded, setIsHistoryExpanded] = useState(false)
    const [isEditing, setIsEditing] = useState(false)


    //progress bar
    const [progressBarCompletion, setProgressBarCompletion] = useState("")
    const [persentCompletion, setPersentCompletion] = useState(13)

    useEffect(()=>{
        let progress =2;
        if(account.userData?.emailVerified){
            progress++;
        }
        if(account.userData?.firstName && account.userData?.firstName !== "Anonymous"){
            progress++;
        }
        if(account.userData?.lastName && account.userData?.lastName !== "User"){
            progress++;
        }
        if(account.userData?.dateOfBirth){
            progress++;
        }
        if(account.userData?.gender){
            progress++;
        }
        if(account.userData?.phoneNumber){
            progress++;
        }
        // if(acceptMarketingCampaigns){
        //     progress++;
        // }

        setProgressBarCompletion("complete"+progress)
        setPersentCompletion(Math.ceil(12.5*progress))
    }, [account.userData])

    const getProgramDuration = (interval, capitilized) =>{
      if(interval === 1){
          return capitilized ? "μηνιαια": "μηνιαία"
      }
      return interval+"μηνη"
    }

    const handleInputChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handlePersonalSubmit = () =>{
        addLoading("ACCOUNTPERSONALSUBMIT");
        setIsEditing(false);
        AccountsService.patchUser(Object.entries({...formData, phoneNumber: !!phoneNum && phoneNum !== account.userData?.phoneNumber && isValidPhoneNumber(phoneNum) ? phoneNum :''})
            .filter(([key, value]) => value  && value.trim() !== '')
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {}))
            .then((response) => {
                navigate(0);
            })
            .catch((error) => {
                if (error.response.data.code === 403) {
                    if(error.response.data.token){
                        if(!!phoneNum && phoneNum !== account.userData?.phoneNumber){
                            if(isValidPhoneNumber(phoneNum)){
                                sessionStorage.setItem("cardValidationToken", error.response.data.token)
                                CardValidationService.generateOTP(
                                    {
                                        phoneNumber: phoneNum,
                                    },
                                    error.response.data.token
                                )
                                    .then((response) => {
                                        setPhoneNumberVerify(true);
                                        sessionStorage.setItem("phoneVerificationToken", response.data.token);
                                    })
                                    .catch((error) => {
                                        //todo error return from back
                                        console.log("Failure: generateOTP")
                                    })
                                    .finally(()=>{
                                        removeLoading("ACCOUNTPERSONALSUBMIT")
                                    })
                            }else{
                                //should never go here
                                setIsPhoneNumberError(true);
                                setIsEditing(true);
                                removeLoading("ACCOUNTPERSONALSUBMIT")
                            }
                        }else {
                            //should never go here
                            removeLoading("ACCOUNTPERSONALSUBMIT")
                        }
                    }else{
                        removeLoading("ACCOUNTPERSONALSUBMIT")
                        //todo error return from back
                    }
                }else{
                    removeLoading("ACCOUNTPERSONALSUBMIT")
                    console.log("Failure: patchUser")
                    navigate(0);
                    //todo error return from back
                }
            });
    }

    const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
    const phoneNumberCodes = [{text: "GR", code: "+30"}, {text: "CY", code: "+357"}]
    const [phoneNumberVerify, setPhoneNumberVerify] = useState(false);
    const closeEdit = ()=>{
        setIsPhoneNumberError(false);
        setFormData({
            firstName: account.userData?.firstName && account.userData?.firstName !== "Anonymous" ? account.userData?.firstName : "",
            lastName: account.userData?.lastName && account.userData?.lastName !== "User" ? account.userData?.lastName : "",
            dateOfBirth: account.userData?.dateOfBirth,
            gender: account.userData?.gender
        })
        setPhoneNum(account.userData?.phoneNumber ? account.userData?.phoneNumber : "");
        setIsEditing(false)
    }

    const handleCancelCancellation = () =>{
        addLoading("HANDLECANCELCANCELLATION")
        AccountsService.restartSubscription().then((response) =>{
            navigate(0)
        }).catch((error)=>{
            console.log("Failure: restartSubscription")
            //todo handle cancel cancellation in case of error
        })
    }

    return (
    <div>
      {isLoading() ? (
        <Loader />
      ) : (
          <div className={"account-wrapper"}>
              <div className={"account-header"}>
                  <span className={"account-title"}>
                      O λογαριασμός μου
                  </span>
                  <div className={"account-progress-section"}>
                      <div className={"account-progress-section-top"}>
                          <span>Συμπλήρωση προφίλ</span>
                          <span>{persentCompletion}%</span>
                      </div>
                      {persentCompletion === 100 ?
                          <span className={"account-progress-section-middle"}>Το προφίλ σου είναι συμπληρωμένο!</span>
                          :
                          <span className={"account-progress-section-middle"}>
                              Ολοκλήρωσε το προφίλ σου για να μπορείς να έχεις την ολοκληρωμένη εμπειρία του ANT1+
                          </span>
                      }
                      <div className={`account-progress-section-bot ${progressBarCompletion}`}></div>
                  </div>
              </div>
              <div className={"account-content"}>
                  <div className={"account-data"}>
                      <div className={"account-connect-data"}>
                          <span className={"account-connect-data-title"}>Στοιχεία σύνδεσης</span>
                          <div className={"account-connect-data-wrapper"}>
                              <div className={"account-textboxes-grouped"}>
                                  <TextBox fieldTitle={"EMAIL"}
                                           defaultValue={account.userData?.email} redirectEdit={()=> UserService.updateProfile(account.language)}
                                           hasIcon={account.userData?.emailVerified ? "images/emailVerified.png" : "images/emailNeedsAction.png"}
                                           iconAlt={account.userData?.emailVerified ? "email is verified" : "email needs to be verified"}
                                           iconOnClick={!(account.userData?.emailVerified) ? ()=>{
                                               setVerificationPopup(true);
                                               setVerificationErrorPopup(false);
                                           } : undefined}/>
                                  <TextBox fieldTitle={"ΚΩΔΙΚΟΣ"} defaultValue={"************"} titleInfo={<div className={"account-code-tooltip-elements"}>
                                      <span>O κωδικός πρέπει να αποτελείται από τουλάχιστον:</span>
                                      <ul>
                                          <li>8 χαρακτήρες</li>
                                          <li>ένα κεφαλαίο γράμμα</li>
                                          <li>ένα πεζό γράμμα</li>
                                          <li>έναν αριθμό</li>
                                      </ul>
                                  </div>} type={"password"} redirectEdit={()=>UserService.updatePassword(account.language)} />
                              </div>
                              <div className={"account-ads-checkbox"}>
                                  <CheckBox disabled={marketingDisabled} action={onAcceptMarketingChange} checked={acceptMarketingCampaigns} text={"Αποδέχομαι να λαμβάνω ενημερώσεις και αποκλειστικές προσφορές από το antennaplus.gr."}/>
                              </div>
                          </div>
                      </div>
                      <div className={"account-personal-data"}>
                          <div className={"account-personal-data-header"}>
                              <span className={"account-personal-data-title"}>Προσωπικά στοιχεία</span>
                              {!isEditing ?
                                  <span className={"account-personal-data-header-edit"} onClick={()=>{setIsEditing(true)}}>Επεξεργασία</span>
                                  :
                                  <div className={"account-personal-data-header-isEditing account-desktop"}>
                                      <span className={"account-personal-data-header-cancel"} onClick={closeEdit}>Ακύρωση</span>
                                      <span className={"account-personal-data-header-edit"} onClick={handlePersonalSubmit}>Αποθήκευση</span>
                                  </div>
                              }
                          </div>
                          <div className={"account-personal-data-wrapper"} onDoubleClick={()=>{setIsEditing(true)}}>
                              <div className={"account-textboxes-grouped"}>
                                  <TextBox fieldTitle={"ΟΝΟΜΑ"} fieldName={"firstName"} defaultValue={formData.firstName} onChange={(e)=>{handleInputChange(e.target.name, e.target.value)}}  disabled={!isEditing}/>
                                  <TextBox fieldTitle={"ΕΠΙΘΕΤΟ"} fieldName={"lastName"} defaultValue={formData.lastName} onChange={(e)=>{handleInputChange(e.target.name, e.target.value)}} disabled={!isEditing}/>
                              </div>
                              <div className={"account-textboxes-grouped"}>
                                  <CustomDatePicker fieldTitle={"ΗΜΕΡΟΜΗΝΙΑ ΓΕΝΝΗΣΗΣ"} onChange={(date)=>{handleInputChange("dateOfBirth", date)}} defaultValue={formData.dateOfBirth} disabled={!isEditing}/>
                                  <CustomSelectBox fieldTitle={"ΦΥΛΟ"} fieldName={"gender"} defaultValue={formData.gender} customOnChange={(value) => {handleInputChange("gender", value)}} disabled={!isEditing} options={["Γυναίκα", "Άντρας", "Άλλο"]} placeholder={"Επιλογή"}/>
                              </div>
                              <div className={"account-textboxes-grouped"}>
                                  <TextBox fieldTitle={"ΤΗΛΕΦΩΝΟ"} fieldName={"phoneNumber"} type={"phoneNumber"} defaultValue={phoneNum} isError={isPhoneNumberError} onChange={(value) => {
                                      setIsPhoneNumberError(false);
                                      setPhoneNum(value)
                                  }} disabled={!isEditing} options={phoneNumberCodes}/>
                                  <span className={"account-textbox-placeholder"}>{isEditing ? "":""}</span>
                              </div>
                          </div>
                          {isEditing &&
                              <div className={"account-mobile"}>
                                  <div className={"account-personal-data-header-isEditing"}>
                                      <span className={"account-personal-data-header-cancel"} onClick={closeEdit}>Ακύρωση</span>
                                      <span className={"account-personal-data-header-edit"} onClick={handlePersonalSubmit}>Αποθήκευση</span>
                                  </div>
                              </div>
                          }

                      </div>
                  </div>
                  <div className={`subscriptionInformation-wrapper ${gradientGetter(account.userData?.subscription?.product.name)}`}>
                      <div className={`subscriptionInformation`}>
                          <div className={"subscriptionInformation-package"}>
                              <div className={"subscriptionInformation-package-content"}>
                                  <span className={"subscriptionInformation-package-title"}>Το πρόγραμμά μου</span>
                                  <div className={"subscriptionInformation-package-top"}>
                                      <span className={`subscriptionInformation-package-package ${gradientGetter(account.userData?.subscription?.product.name)}`}>{account.userData?.subscription?.product.name}</span>
                                      {((!hasUnsubscribed && (hasFreeProduct || canUpgrade)) && account.userData?.subscription?.state !== "canceled") &&
                                          <span className={"subscriptionInformation-package-upgrade"} onClick={onUpgrade}>Αλλαγή προγράμματος</span>
                                      }
                                  </div>
                                  {(hasUnsubscribed && !hasFreeProduct) || account.userData?.subscription?.state === "canceled"  ?
                                      <div className={"subscriptionInformation-hasUnsubscribed"}>
                                          <img src={"images/account/unsubscribeIcon.svg"} alt={"unsubscribe icon"} />
                                          {account.userData?.subscription?.state === "canceled" ?
                                              <span>Έχει πραγματοποιηθεί ακύρωση της συνδρομής σου. Θα μεταβείς στο πρόγραμμα ΑΝΤ1+ Free εντός των επόμενων ωρών.</span>:
                                              <span>Έχεις πραγματοποιήσει ακύρωση της συνδρομής σου και θα μεταβείς σε ΑΝΤ1+ Free στις {formatDate(account.userData?.subscription?.current_period_ends_at)}. Σε περίπτωση που άλλαξες γνώμη και θέλεις να παραμείνεις συνδρομητής πάτησε <span className={"clickable"} onClick={handleCancelCancellation}>εδώ</span></span>
                                          }
                                      </div>
                                  :
                                      !hasFreeProduct && (account.userData?.renewalPreview?.next_assessment_at) ?
                                      <>
                                          {account.userData?.subscription?.state !== "canceled" ?
                                              <div className={"subscriptionInformation-bot-wrapper"}>
                                                  {!hasFreeProduct &&
                                                      <span className={"subscriptionInformation-bot-duration"}>{getProgramDuration(account.userData?.subscription?.product.interval, true)} συνδρομη</span>
                                                  }
                                                  <div className={"subscriptionInformation-bot"}>
                                                      <div className={"subscriptionInformation-bot-texts"}>
                                                          <span className={"field-title"}>ΠΟΣΟ ΕΠΟΜΕΝΗΣ ΧΡΕΩΣΗΣ </span>
                                                          <span className={"field-data"}>{(account.userData?.subscription?.state ==="past_due" ? account.userData?.renewalPreview?.existing_balance_in_cents : account.userData?.renewalPreview?.total_amount_due_in_cents)/100}€</span>{/*todo*/}
                                                      </div>
                                                      <div className={"subscriptionInformation-bot-texts"}>
                                                          <span className={"field-title"}>ΗΜΕΡΟΜΗΝΙΑ ΕΠΟΜΕΝΗΣ ΧΡΕΩΣΗΣ </span>
                                                          <span className={"field-data"}>{formatDate(account.userData?.subscription?.state ==="past_due" ? account.userData?.subscription?.next_assessment_at : account.userData?.renewalPreview?.next_assessment_at)}</span>
                                                      </div>
                                                  </div>
                                              </div>
                                          :<></>}
                                          {account.userData?.subscription?.state ==="past_due" ?
                                              <div className={"subscriptionInformation-hasUnsubscribed past_due"}>
                                                  <img src={"images/account/unsubscribeIcon.svg"} alt={"unsubscribe icon"}/>
                                                  <span>H χρέωση της συνδρομής σου απέτυχε. Αυτό μπορεί να οφείλεται σε ανεπαρκές υπόλοιπο ή σε λήξη της αποθηκευμένης κάρτας. Η επόμενη προσπάθεια χρέωσης θα γίνει αυτόματα στις {formatDate(account.userData?.subscription?.next_assessment_at)}.</span>
                                              </div>:<>
                                                  {!!account.userData?.subscription?.next_product_price_point_id ?
                                                      <div className={"subscriptionInformation-hasUnsubscribed"}>
                                                          <img src={"images/account/unsubscribeIcon.svg"}
                                                               alt={"unsubscribe icon"}/>
                                                          <span>Έχεις αιτηθεί αλλαγή του προγράμματός σου, η οποία θα πραγματοποιηθεί στις {formatDate(account.userData?.subscription?.current_period_ends_at)} και θα μεταβείς από το πρόγραμμα {account.userData?.subscription?.product.name} {getProgramDuration(account.userData?.subscription?.product.interval)} συνδρομή στο πρόγραμμα {account.userData?.subscription?.next_product_name ?? account.userData?.subscription?.product.name} {getProgramDuration(account.userData?.subscription?.next_price_point_interval)} συνδρομή.</span>
                                                      </div> : <></>}
                                              </>
                                          }
                                      </>:<></>
                                  }
                              </div>
                              {(!hasUnsubscribed && !hasFreeProduct &&
                                  account.userData?.subscription?.state !== "canceled" &&
                                  account.userData?.subscription?.state !== "past_due") ?
                                  <span className={"subscriptionInformation-cancel"} onClick={onUnsubscribeHandle}>Θέλω να ακυρώσω τη συνδρομή μου</span>:<></>
                              }
                          </div>
                          {account.userData?.subscription?.state !== "canceled" ?
                              (!hasUnsubscribed && !hasFreeProduct && hasUsedCoupon && account.userData?.couponsInformation?.filter(item => item.discount_type !== "amount").slice(account.userData?.subscription?.coupon_uses_allowed - account.userData?.subscription?.coupon_use_count ? 0 : 1).length) ?
                                  <div className={`subscriptionInformation-voucher ${gradientGetter(account.userData?.subscription?.product.name)}`}>
                                      <div className={"subscriptionInformation-voucher-header"}>
                                          <span className={"subscriptionInformation-voucher-title"}>Ενεργό Κουπόνι</span>
                                          <span className={"subscriptionInformation-voucher-add desktop"} onClick={onCashCoupon}>Προσθήκη</span>
                                      </div>
                                      {account.userData?.couponsInformation?.filter(item => item.discount_type !== "amount").slice(account.userData?.subscription?.coupon_uses_allowed - account.userData?.subscription?.coupon_use_count ? 0 : 1).length ?
                                          <div className={"subscriptionInformation-voucher-wrapper"}>
                                              <div className={"subscriptionInformation-voucher-wrapper-child"}>
                                                  {account.userData?.couponsInformation.filter(item => item.discount_type !== "amount").slice(account.userData?.subscription?.coupon_uses_allowed - account.userData?.subscription?.coupon_use_count ? 0 : 1).map((item, index) =>{
                                                      return(<div key={index} className={`subscriptionInformation-voucher-item-wrapper ${account.userData?.couponsInformation?.filter(item => item.discount_type !== "amount").slice(account.userData?.subscription?.coupon_uses_allowed - account.userData?.subscription?.coupon_use_count ? 0 : 1).length === 1 ? "gapped":""}`}>
                                                              <span className={"subscriptionInformation-voucher-name"}>{item?.description}</span>
                                                          </div>)
                                                      })}
                                                  <div className={"subscriptionInformation-voucher-texts"}>
                                                      <span className={""}>ΥΠΟΛΟΙΠΟ ΔΩΡΕΑΝ ΧΡΗΣΗΣ:</span>
                                                      <span className={"field-data"}>
                                                          {(account.userData?.couponsInformation[0].discount_type === "amount") ?
                                                              account.userData?.couponsInformation?.filter(item => item.discount_type !== "amount").reduce((prev, item) => prev + item.duration_period_count+1, 0)
                                                              :
                                                              account.userData?.couponsInformation?.filter(item => item.discount_type !== "amount").slice(1).reduce((prev, item) => prev + item.duration_period_count+1, 0)+
                                                              account.userData?.subscription?.coupon_uses_allowed - account.userData?.subscription?.coupon_use_count
                                                          } μήνες</span>
                                                  </div>
                                              </div>
                                          </div>:<></>
                                      }
                                      <div className={"subscriptionInformation-voucher-header"}>
                                          <span className={"subscriptionInformation-voucher-add mobile"} onClick={onCashCoupon}>Προσθήκη</span>
                                      </div>
                                  </div>
                                  :
                                  <div className={`subscriptionInformation-voucher ${gradientGetter(account.userData?.subscription?.product.name)}`}>
                                      <div className={"subscriptionInformation-voucher-header"}>
                                          <span className={"subscriptionInformation-voucher-title"}>Έχεις κωδικό προσφοράς;</span>
                                      </div>
                                      <AccountEnterCoupon account={account} navigate={navigate} />
                                  </div>:<></>
                          }
                      </div>
                      {(account.userData?.vouchers?.filter(voucher => voucher?.provider === "VILLAGE")?.length &&
                          VILLAGE_ELLIGIBLE_PRODUCTS.includes(account.userData?.subscription?.product.name))  ?
                          <div className={"subscriptionInformation-village"}>
                              <div className={"subscriptionInformation-village-header"}>
                                  <span>Το εισιτήριό μου</span>
                                  <img src={"images/VILLAGE CINEMAS_LOGO Color White 2 - big.png"} alt={"village logo"}/>
                              </div>
                              <div className={"subscriptionInformation-village-content"}>
                                  <div className={"subscriptionInformation-village-voucher"} onClick={()=>{
                                      if(window.isSecureContext)
                                          navigator.clipboard.writeText(account.userData?.vouchers?.find(voucher => voucher?.provider === "VILLAGE").value);}}>
                                      <span>{account.userData?.vouchers?.find(voucher => voucher?.provider === "VILLAGE").value}</span>
                                      <img src={"images/account/copyIcon.svg"} alt={"click to copy on clipboard"}/>
                                  </div>
                                  <div className={"subscriptionInformation-village-field"}>
                                      <span className={"subscriptionInformation-village-field-title"}>Διαθέσιμο μέχρι </span>
                                      <span className={"subscriptionInformation-village-field-data"}>{periodEndDate}</span>
                                  </div>
                              </div>
                              <span className={"subscriptionInformation-village-description"}>Μπες στο villagecinemas.gr και βάλε τον κωδικό στο πεδίο «Προσθήκη voucher» κατά την αγορά του εισιτηρίου σου και δες δωρεάν όποια ταινία θέλεις!</span>
                          </div>
                          :<></>
                      }
                  </div>

                  {!!paymentProfileList.length ?
                      <div className={"card-section"}>
                      <span className={"card-section-header"}>Στοιχεία πληρωμής</span>
                      <div className={"card-section-data"}>
                          <div className={"card-section-data-data"}>
                              <div className={"card-title"}>
                                  <span>Οι κάρτες μου</span>
                                  <button disabled={paymentProfileList.length > 2} className={"card-title-button"} onClick={onChangePaymentInfo}>Προσθήκη</button>
                              </div>
                              <CardCarousel list={paymentProfileList} hasActiveCard={account.userData.subscription?.credit_card?.id === paymentProfileList[0].id}/>
                          </div>
                          {account.userData?.paymentsHistory?.filter(it => it.product_name !== Products.FREE && it.status === "paid").length ?
                              <div className={"card-section-history"}>
                                  <div className={"card-section-history-wrapper"}>
                                      <span className={"card-section-history-header"}>Ιστορικό χρεώσεων</span>
                                      <div className={"card-section-history-data"}>
                                          {account.userData?.paymentsHistory?.filter(it => it.product_name !== Products.FREE && it.status === "paid").slice(...(isHistoryExpanded ? [0] : [0, 3])).map((it, index) => {
                                              return (<div className={"card-section-history-item-wrapper"} key={index}>
                                                  <div className={`card-section-history-item ${gradientGetter(it.product_name)}`}>
                                                      <div className={"card-section-history-item-data"}>
                                                          <div className={"card-section-history-item-two-group"}>
                                                              <div className={"card-section-history-item-field"}>
                                                                  <span
                                                                      className={"card-section-history-item-field-top"}>ΠΡΟΓΡΑΜΜΑ</span>
                                                                  <span
                                                                      className={"card-section-history-item-field-bot"}>{it.product_name}</span>
                                                              </div>
                                                              <div className={"card-section-history-item-field"}>
                                                                  <span className={"card-section-history-item-field-top"}>ΠΟΣΟ ΧΡΕΩΣΗΣ</span>
                                                                  <span
                                                                      className={"card-section-history-item-field-bot"}>{it.paid_amount}€ </span>
                                                              </div>
                                                          </div>
                                                          <div className={"card-section-history-item-field"}>
                                                              <span className={"card-section-history-item-field-top"}>ΗΜΕΡΟΜΗΝΙΑ  ΧΡΕΩΣΗΣ</span>
                                                              <span
                                                                  className={"card-section-history-item-field-bot"}>{formatDate(it.paid_date)}</span>
                                                          </div>
                                                      </div>
                                                      {/*<div className={"card-section-history-item-receipt"} onClick={()=>{*/}
                                                      {/*    navigate("/invoice",  {*/}
                                                      {/*        state: {*/}
                                                      {/*            id: it,*/}
                                                      {/*        }});*/}
                                                      {/*}}>*/}
                                                      {/*    <img src={"images/account/downloadInvoice.svg"} alt={"view invoice"}/>*/}
                                                      {/*    <span>Λήψη Παραστατικού</span>*/}
                                                      {/*</div>*/}
                                                  </div>
                                              </div>)
                                          })}
                                      </div>
                                  </div>
                                  {account.userData?.paymentsHistory?.filter(it => it.product_name !== Products.FREE && it.status === "paid").length > 3 ?
                                      <>
                                          {isHistoryExpanded ?
                                              <span className={"card-section-history-expandMore"}
                                                    onClick={() => setIsHistoryExpanded(false)}>Λιγότερα</span> :

                                              <span className={"card-section-history-expandMore"}
                                                    onClick={() => setIsHistoryExpanded(true)}>Περισσότερα</span>

                                          }
                                      </>:<></>
                                  }
                              </div>: <></>
                          }
                      </div>
                  </div>:<></>
                  }
                  <div className={"disconnect-section"}>
                      <span onClick={logoutFromAllDevices}>Αποσύνδεση από όλες τις συσκευές</span>
                  </div>
              </div>
          </div>
      )}
      <PopUp
          openPopUp={verificationPopup}
          setOpenPopUp={setVerificationPopup}
          maxWidth="md"
          showCloseButton={true}
          outlined={false}
          error={false}
          closeWhenClickedOutside={true}
      >
        <GenericError
            setClose={setVerificationPopup}
            success={true}
            mainText={"Μη επιβεβαιωμένο email"}
            buttonText={"Αποστολή email"}
            showIcon={false}
            executeFunction={sendVerificationEmail}>
            <div className={"account-email-popup-secondary-wrapper"}>
                <span className={"account-email-popup-secondary-first"} >Κάνε κλικ στον παρακάτω σύνδεσμο για να λάβεις ένα μήνυμα επιβεβαίωσης στο email που μας έχεις δηλώσει.</span>
                <span className={"account-email-popup-secondary-sub"} >Παρακαλούμε ελέγξτε και τον φάκελο με τα ανεπιθύμητα.</span>
            </div>
        </GenericError>
      </PopUp>
      <PopUp
          openPopUp={verificationErrorPopup}
          setOpenPopUp={setVerificationErrorPopup}
          maxWidth="md"
          showCloseButton={true}
          outlined={false}
          error={false}
          closeWhenClickedOutside={true}
      >
        <GenericError
            setClose={setVerificationErrorPopup}
            success={false}
            mainText={"Problem with sending email verification"}
            secondaryText={"There was a problem with sending your email verification. Please try again later."}
            hideButtons={true}
            showIcon={true}
        />
      </PopUp>
      <PopUp
          openPopUp={verificationSuccessPopup}
          setOpenPopUp={setVerificationSuccessPopup}
          maxWidth="md"
          showCloseButton={true}
          outlined={false}
          error={false}
          closeWhenClickedOutside={true}
      >
        <GenericError
            setClose={setVerificationSuccessPopup}
            success={true}
            mainText={"Εστάλη email επιβεβαίωσης"}
            hideButtons={true}
            showIcon={true}>
            <div className={"account-email-popup-secondary-wrapper"}>
                <span className={"account-email-popup-secondary-sub"} >Παρακαλούμε ελέγξτε και τον φάκελο με τα ανεπιθύμητα.</span>
            </div>
        </GenericError>
      </PopUp>
        <PopUp
            openPopUp={phoneNumberVerify}
            setOpenPopUp={setPhoneNumberVerify}
            maxWidth="md"
            showCloseButton={true}
            outlined={false}
            error={false}
            closeWhenClickedOutside={true}
        >
            <PinVerification
                showBackButton={false}
                isModal={true}
                mobile={phoneNum}
                successAction={(token)=>{
                    addLoading("ACCOUNTPINVERIFICATIONSUCCESS")
                    AccountsService.patchUser({phoneNumber: phoneNum, flowToken: token  })
                        .then((response) => {
                            navigate(0);
                        })
                        .catch((err) => {

                        })
                        .finally(()=>{
                            removeLoading("ACCOUNTPINVERIFICATIONSUCCESS")
                        })
                }}
            />
        </PopUp>
    </div>
  );
}


function AccountEnterCoupon({account, navigate}){
    const [coupon, setCoupon] = useState("");
    const [error, setError] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);

    const handleChange = (e) => {
        e.preventDefault();
        setInputError(false);
        //setDisabledButton(e.target.value.length !== 16);
        setDisabledButton(e.target.value.replace(/\s/g, "").length === 0);
        setCoupon(e.target.value);
        setError(false);
    };

    const onValidateCoupon = (e) => {
        e.preventDefault();
        setDisabledButton(true);
        CouponsService.checkCouponValidity(coupon)
            .then((response) => {
                setError(false);
                sessionStorage.setItem("discount-coupon", coupon);
                if (UserService.isLoggedIn()) {
                    navigate("/PickProgram");
                } else {
                    // console.debug("Retrying authorization")
                    try{
                        UserService.doLogin(process.env.REACT_APP_ENTER_COUPON_REDIRECT_URI,account.language);
                    }catch (e){
                        // console.debug("Error slow connection")
                    }
                }
            })
            .catch((error) => {
                setError(true);
            })
            .finally(() => {
                setDisabledButton(false);
            });
    };
    return (<div id="account-enter-coupon">
            <form autoComplete="off" onSubmit={onValidateCoupon} className={"account-coupon-wrapper"}>
                <div className={"account-coupon-inner-wrapper"}>
                    <div className={`account-coupon-textbox-wrapper ${error || inputError ? "account-coupon-error" : ""}`}>
                        <div className={"account-coupon-input"}>
                            <input type={"text"} placeholder={"Πληκτρολόγησε τον κωδικό εδώ"} onChange={handleChange} value={coupon}/>
                        </div>
                        <button type={"submit"}
                                disabled={disabledButton}
                        >
                            <div className={"account-coupon-textbox-button"}></div>
                        </button>
                    </div>
                    {(error || inputError) &&
                        <span>Ο κωδικός δεν είναι έγκυρος</span>
                    }
                </div>
            </form>
    </div>)
}

export default Account;