import React, {useEffect, useRef, useState} from "react";
import CmsHelper, {PickProgramPageHelper} from "../../../Utilities/CmsHelpers";
import FlowContainer from "../../../PickProgram/FlowElements/FlowContainer";
import {useCms} from "../../../../context/CmsContext";
import GoogleAnalytics from "../../../../analytics/GoogleAnalytics";
import AwsApiService from "../../../../services/AwsApiService";
import {useAccount} from "../../../../context/AccountContext";
import AccountsService from "../../../../services/AccountsService";
import {useNavigate} from "react-router-dom";
import UserService from "../../../../services/UserService";

export default function RegistrationFlow({packages, couponExists, handleNextStep,
                                             contestSession, promoTargetDisplayName}) {
    const account = useAccount();
    const {pickProgramData} = useCms();

    const navigate = useNavigate();

    const [products, setProducts] = useState([])

    useEffect(()=>{
        let products = [...packages];

        if (!(!!couponExists) && !!promoTargetDisplayName) {
            const targetDisplayNames = JSON.parse(promoTargetDisplayName);
            products = products.filter(item1 => targetDisplayNames.some(item2 => item1.displayName === item2))
        }

        if(contestSession){
            products = products.filter(product => product.displayName !== "Lite")
        }

        setProducts(products);

    }, [packages, couponExists, contestSession, promoTargetDisplayName])

    const [iframeUrl, setIframeUrl] = useState(undefined);
    const formRef = useRef(null)

    const [loading, setLoading] = useState(false);
    const onNext = (product, setCardInUse, setError) =>{

        let request = {
            pricePoint: product.pricePoint,
        };

        if (!!couponExists) {
            request = {
                ...request,
                couponCode: couponExists,
            };
        }

        account.setChosenProgramName(
            packages.find((obj) => obj.productId === product.productId).name
        );
        GoogleAnalytics.onSelectPackage();
        AwsApiService.onPickProgram();


        sessionStorage.removeItem("selectedProgramId");

        setLoading(true)

        AccountsService.subscribe(request)
            .then((response) => {
                
                handleNextStep(true);

                if(response.data.code === 2){
                    sessionStorage.setItem("kc_resolve_token", response.data.token);
                    navigate("/CongratsPage");
                } else setIframeUrl(new URL(response.data.uri));
            })
            .catch((err) => {
                //TODO handle error
            })
            .finally(()=>{
                setLoading(false)
            });
    }

    useEffect(() => {
        const handleMessage = (event)=>{
            if (!iframeUrl || event.origin !== iframeUrl?.origin) {
                return;
            }
            const data = JSON.parse(event.data);
            if((parseInt(data.status) === 1)){
                console.log("Successful flow")
                AccountsService.checkSubscriptionPostAction()
                    .then(r =>{
                        sessionStorage.setItem("kc_resolve_token", r.data.token);
                    })
                handleNextStep(true);
                if (UserService.isSubscriptionAction()) {
                    navigate("/CongratsPage");
                } else {
                    navigate("/CongratsPage");
                }
            }
        }

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [iframeUrl]);  //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if (formRef.current && iframeUrl) {
            formRef.current.submit()
        }
    }, [iframeUrl, formRef])

    return <>
        {iframeUrl ?
            <div style={{flex: 1, display: "flex"}}>
                <form hidden={true}
                      target={"ev-iframe"}
                      action={!!iframeUrl ? iframeUrl?.origin + iframeUrl?.pathname : ""}
                      method={"POST"} ref={(reference) => formRef.current = reference}>
                    {iframeUrl && iframeUrl.search
                        .replace("?", "")
                        .split("&")
                        .map((token) => {
                            const tokens = token.split("=")
                            return <input key={tokens[0]} name={tokens[0]} defaultValue={tokens[1]}/>
                        })}
                </form>
                <iframe name={"ev-iframe"} title={"ev-checkout"} src={iframeUrl}
                                      style={{width: '100%', height: '100vh', border: 'none'}}
                />
            </div>:
        <FlowContainer packages={products}
                       loading={loading}
                       update={false}
                       couponExists={!!couponExists}
                       contestState={contestSession}
                       cmsData={PickProgramPageHelper.getPrograms(pickProgramData, CmsHelper.REGISTRATION)}
                       onNext={onNext}
        />
        }
    </>
}